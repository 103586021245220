// @import "variables";
$white: #fff;
$unit: 1rem;

.userDropDownBtn_wrap {
  position: relative;
  // height: 100%;
}

.userDropDownMenu_wrap {
  position: absolute;
  right: 0;
  width: 600px;
}

.userDropDownMenu {
  position: absolute;
  right: 0;
  top: 16px;
  background: $white;
  border-radius: 3px;
  padding: ($unit * 0.2) 0;
  min-width: ($unit * 10);
  border: 1px #ddd solid;
  box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.1);
  z-index: 100;
  &.hidden {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      button {
        padding: 0 $unit;
        width: 100%;
      }
    }
  }
}

.userDropDownIcon {
  height: 100%;
  button {
    height: ($unit * 2);
    width: ($unit * 2);
    padding: 1px;
    margin: 0;
  }

  img {
    height: 100%;
    width: 100%;
    // height: $unit;
  }
}
