.loadingPanel {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 200;
  text-align: center;
  opacity: 1;
  min-height: 10rem;
  display: "flex";
  justify-content: "center";
  align-content: "center";
  align-items: "center";
  transition: opacity 0s ease;

  * {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    font-size: 1em;
  }

  &.hidden {
    transition: opacity 0.8s ease;
    opacity: 0;
    pointer-events: none;
  }
  &.collapsed {
    top: 99%;
    pointer-events: none;
  }
}
